.sign-in-container {
    background: #F9F9F9;
    width: 100vw;
    height: 100vh;
}

.sign-in-container-box {
    position: relative;
    background: #fff;
    border-radius: 107px 0px 107px 0px;
    max-height: 500px;
    max-width: 702px;
    width: 100%;
    height: 100%;
}

.sign-in-box {
    position: absolute;
    top: -30%;
}

.sign-in-box-element {
    margin-bottom: 0.5rem;
}

.sign-in-container-box-image {
    max-height: 290px;
    max-width: 290px;
    width: 100%;
    height: 100%;
}