.nav-bar-item-container {
    background-color: azure;
    max-width: 355px;
    width: 100%;
    min-height: 70px;
    max-height: 100px;
    height: auto;
    border-radius: 30px;
    color: #93b0c8;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    gap: 10px;
}

.nav-bar-item-container>img {
    max-width: 70px;
    width: 100%;
    height: auto;
    object-fit: scale-down;
}