* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Roboto Light", serif;
}

a
{
    text-decoration: none;
    color: inherit;
}

.orange-input-field {
    height: 74px;
    max-width: 494px;
    width: 100%;
}

.table
{
    background: #fff;
    color: #111;
    max-width: 1800px !important;
    width: 100% !important;
    min-height: 500px;
    height: auto;
    margin: 1.2rem 0;
}