.header-box
{
    background-color: #10163A;
    color: #fff;
    padding: 1.5rem;
    text-align: center;
}

.header-image {
    max-height: 290px;
    max-width: 290px;
    width: 100%;
    height: 100%;
}