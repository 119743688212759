.service-container {
    position: relative;
    width: 300px;
    height: 300px;
    flex-shrink: 0;
    border-radius: 30px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    overflow: hidden;
}

.service-container a {
    color: inherit;
    text-decoration: none;
}

.service-container img {
    position: absolute;
    right: 0;
    bottom: 0;
    opacity: .4;
}